import { FormulaDataModule } from 'models';
import { FC, memo } from 'react';

import { FormulaElement } from '../../../../../_molecules';
import * as Styled from './FormulaPreview.styled';

export type FormulaPreviewProps = FormulaDataModule;

const FormulaPreview: FC<FormulaPreviewProps> = ({
  title,
  config: { formula } = {},
}) => (
  <Styled.StyledFormulaPreview className="esg-formula-preview">
    <div className="esg-formula-preview__label">{title}</div>

    <div className="esg-formula-preview__formula">
      <FormulaElement formula={formula} error={!formula} />
    </div>
  </Styled.StyledFormulaPreview>
);

export default memo(FormulaPreview);
